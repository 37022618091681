import type { NextPage } from 'next';
import Image from 'next/image';
import { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { Button } from '@arkestro/arkestro-design-system';
import Head from 'components/shared/Head/Head';
import { FULL_LOGO_WHITE_URL } from 'utils/constants';

const Custom404Page: NextPage = () => {
  const { t } = useTranslation('common');

  useEffect(() => {
    document.body.classList.add('primary-themed-background');
    document.body.classList.add('login-layout');
    document.body.classList.remove('sso-login');

    return () => {
      document.body.classList.remove('primary-themed-background');
      document.body.classList.remove('login-layout');
      document.body.classList.remove('sso-login');
    };
  });

  return (
    <>
      <Head title={t('general.page_not_found')} />
      <div className='vertical-table'>
        <div className='vertical-table-cell'>
          <div className='centered'>
            <div className='logo-container'>
              <Image
                width={180}
                height={46}
                layout='intrinsic'
                className='login-logo'
                src={FULL_LOGO_WHITE_URL}
                alt={t('general.logo')}
              />
            </div>
            <div className='login-fields-container sign-in'>
              <div className='title'>{t('general.page_not_found')}</div>
              <div className='action'>
                <Button type='link'>
                  <Link href='/login'>{t('general.go_to_login')}</Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Custom404Page.ignoreLayout = true;
Custom404Page.ignoreAuthorization = true;

export default Custom404Page;
